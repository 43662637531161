<template>
  <div class="about">

    <b-container class="pt-5 pb-5">
      <b-row>
        <b-col col md="8" class=" mb-5">
          <h1> ¿Qué hacemos? </h1>
          <hr class="line sm" />
          <p>El <strong> Instituto de los Mexicanos en el Exterior</strong> (IME) es un órgano desconcentrado de la <strong> Secretaría de Relaciones Exteriores </strong> bajo el ámbito de la Subsecretaría para América del Norte que, desde el 16 de abril de 2003, atiende las iniciativas y necesidades comunitarias de los mexicanos que viven y trabajan fuera de nuestro país.</p>

          <ul>
            <li><a href="http://www.gob.mx/cms/uploads/attachment/file/134219/2003_04_16_MAT_sre.pdf">Decreto original de creación del IME</a></li>
            <li><a href="http://www.gob.mx/cms/uploads/attachment/file/124955/decreime11.pdf">Última reforma publicada al Decreto por el que se crea el IME </a></li>
          </ul>

          <h4 class="mt-4">Misión</h4>
          <p>Promover estrategias, integrar programas, recoger propuestas y recomendaciones de las comunidades, sus miembros, sus organizaciones y órganos consultivos, tendientes a fortalecer sus vínculos con su país de origen y a fomentar su integración en las sociedades en las que residen e interactuan.</p>

          <h4 class="mt-4">Visión</h4>
          <p>Ser un puente de entendimiento a partir del cual se pueda articular una agenda compartida que mediante la asociación de iniciativas y programas de trabajo a largo plazo, contribuyan al mejoramiento e integración de las comunidades mexicanas en el exterior y a fortalecer su identidad cultural y su orgullo por ser mexicanos.</p>

          <h4 class="mt-4">Objetivos y atribuciones</h4>
          <p>El objetivo del Instituto de los Mexicanos en el Exterior (IME) es promover estrategias, integrar programas, recoger propuestas y recomendaciones de las comunidades, sus miembros, sus organizaciones y órganos consultivos, tendientes a elevar el nivel de vida de las comunidades mexicanas en el extranjero, así como ejecutar las directrices que emanen del Consejo Nacional para las Comunidades Mexicanas en el Exterior.</p>

          <h5 class="mt-4">El Instituto de los Mexicanos en el Exterior tiene las siguientes atribuciones:</h5>
          <ol>
            <li>Promover la revalorización del fenómeno migratorio y el trato digno a los 
                mexicanos que viven en el exterior</li>
            <li>Favorecer la creación de espacios de reunión y promover la comunicación con y entre las 
                comunidades mexicanas que viven en el exterior</li>
            <li>Fungir como enlace, en coordinación con las representaciones de México, con las 
                comunidades mexicanas que viven en el exterior</li>
            <li>Establecer una adecuada coordinación con los gobiernos, instituciones y
                organizaciones de los estados y municipios en materia de prevención,
                atención y apoyo a las comunidades mexicanas en el exterior y en otros
                temas afines y complementarios</li>
            <li>Diseñar y promover mecanismos para la ejecución de los programas y
                proyectos propios de las labores del Instituto de los Mexicanos en el
                Exterior</li>
            <li>Organizar y participar en seminarios, conferencias, simposios, coloquios y
                congresos públicos, privados y académicos en materia de migración y
                atención a mexicanos en el exterior</li>
            <li>Recabar y sistematizar las propuestas y recomendaciones, tendientes a
                mejorar el desarrollo social de las comunidades mexicanas en el exterior,
                que formulen órganos consultivos constituidos por representantes de
                dichas comunidades.</li>
          </ol>

          <p></p>

          <p></p>

          <p></p>

        </b-col>

          <b-col cols="4">
            <Sidebar msg="Paginas" />
          </b-col>

      </b-row>
    </b-container>

  </div>
</template>

<script>
import Sidebar from '@/components/layout/Sidebar.vue';

export default{
  name: 'PageAbout',
  components: {
    Sidebar,
  },
}
</script>
